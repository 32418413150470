interface IDates {
    TOTAL_DAYS_IN_A_WEEK: number,
    TOTAL_MILLISECONDS_IN_A_DAY: number;
    MONDAY: number;
}

const dates: IDates = {
    TOTAL_DAYS_IN_A_WEEK: 7,
    TOTAL_MILLISECONDS_IN_A_DAY: 86400000,
    MONDAY: 1,
};

export default dates;