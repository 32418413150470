import React from "react";
import { observer } from "mobx-react-lite";

const Chart = () => {
    return (
        <div className="chart">
                
        </div>);
}

export default observer(Chart);