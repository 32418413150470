import React from "react";
import { observer } from "mobx-react-lite";

import ReactDatePicker from "react-datepicker";

import dates from "../../../../../../app/common/constants/dates";

interface IProps {
    selectedWeek: Date;
    handleChange: (date: Date) => void;
}

const DatePickerSectionCalendar: React.FC<IProps> = ({
    selectedWeek,
    handleChange,
}) => {
    return (
        <ReactDatePicker
            selected={selectedWeek}
            locale="en-GB"
            onChange={handleChange}
            inline
            filterDate={(e) => e.getDay() === dates.MONDAY}
            calendarStartDay={dates.MONDAY} />)
}

export default observer(DatePickerSectionCalendar);