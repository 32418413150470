import { observer } from "mobx-react-lite";

import { confirmEmailResendHandler } from "../handlers/userHandlers";

import CustomButton from "../../../app/layout/common-components/CustomButton";


interface IProps {
    email: string;
}

const ResendConfirmationLink = ({ email }: IProps) => {
    return (<CustomButton 
                text="Resend the email" 
                classes="primary" 
                onClick={() => confirmEmailResendHandler(email)} 
            />);
}

export default observer(ResendConfirmationLink);