import { createContext, useContext } from "react";

import ActivityEntryStore from "./time-tracking/activityEntryStore";
import ActivityReportsStore from "./time-tracking/activityReportsStore";
import ActivityStore from "./settings/activityStore";
import CommonStore from "./commonStore";
import NavigationStore from "./navigationStore";
import UserStore from "./userStore";

interface Store {
    activityEntryStore: ActivityEntryStore;
    activityReportsStore: ActivityReportsStore;
    activityStore: ActivityStore;
    commonStore: CommonStore;
    navigationStore: NavigationStore;
    userStore: UserStore;
}

export const store: Store = {
    activityEntryStore: new ActivityEntryStore(),
    activityReportsStore: new ActivityReportsStore(),
    activityStore: new ActivityStore(),
    commonStore: new CommonStore(),
    navigationStore: new NavigationStore(),
    userStore: new UserStore(),
}

export const StoreContext = createContext(store);

export const useStore = () => {
    return useContext(StoreContext);   
}