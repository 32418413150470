import IActivityTypeDuration from "../../models/features/time-tracking/IActivityTypeDuration";
import IEnumDetails from "../enums/helpers/IEnumDetails";


const compareActivityTypes = (a: IEnumDetails, b: IEnumDetails) => a.value - b.value;

export const sortedActivityTypes = (activityTypes: IEnumDetails[]) => {
    
    activityTypes.sort(compareActivityTypes)
    
    return activityTypes;
}

export const sortedDurations = (durations: IActivityTypeDuration[]) => {
    durations.sort((a, b) => compareActivityTypes(a.activityType, b.activityType));

    return durations;
}

export  const timeTrackingHelper = {
    sortedActivityTypes,
    sortedDurations,
};

export default timeTrackingHelper;