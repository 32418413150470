import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes } from 'react-router-dom';

import ActivityLogEntryDashboard from "../time-tracking/activities/log-entries/dashboard/ActivityLogEntryDashboard";
import ActivitySettingsDashboard from "../settings/activities/dashboard/ActivitySettingsDashboard";
import CalendarPeriodOverview from "../time-tracking/calendar-week-view/overview/CalendarPeriodOverview";
import CalendarWeekViewDashboard from "../time-tracking/calendar-week-view/dashboard/CalendarWeekViewDashboard";
import CustomRedirect from "../../../app/layout/common-components/CustomRedirect";
import CustomToast from "../../../app/layout/common-components/CustomToast";
import CookiePolicy from "../../legal/CookiePolicy";
import Logout from "../../user/forms/Logout";
import MembersTopNav from "../../nav/MembersTopNav";
import NotFound from "../../../app/layout/NotFound";
import PrivacyPolicy from "../../legal/PrivacyPolicy";
import SettingsDashboard from "../settings/dashboard/SettingsDashboard";
import TermsAndConditions from "../../legal/TermsAndConditions";
import Targets from "../time-tracking/calendar-week-view/targets/Targets";

function MemberRoutes() {
    return (
        <div className="member app-container">
            <MembersTopNav />
            <div style={{ paddingTop: '7em' }}>
                <Routes>
                    <Route path='/' element={<CustomRedirect to="/time/overview" />} />

                    <Route path="/login" element={<CustomRedirect to="/" />} />
                    <Route path="/logout" element={<Logout />} />
                    <Route path="/signup" element={<CustomRedirect to="/" />} />
            
                    <Route path="/cookie-policy" element={<CookiePolicy />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-and-conditions" element={<TermsAndConditions />} />

                    <Route path="/activities/:date/:activityType" element={<ActivityLogEntryDashboard />} />

                    <Route path="/time/" element={<CalendarWeekViewDashboard />}>
                        <Route path="overview" element={<CalendarPeriodOverview  />} />
                        <Route path="targets" element={<Targets />} />
                    </Route>

                    <Route path="/settings/" element={<SettingsDashboard />}>
                        <Route path="activities" element={<ActivitySettingsDashboard />} />
                    </Route>

                    <Route path="*" element={<NotFound />} />
                </Routes>
            </div>

            <CustomToast />
        </div>);
}

export default observer(MemberRoutes);