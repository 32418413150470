import React, { ReactElement } from "react";
import { observer } from "mobx-react-lite";

import { Form, Formik } from "formik";

import CustomButton from "../common-components/CustomButton";

interface IProps {
    initialValues: any;
    handleSubmit: (x: any) => void;
    handleCancel: () => void;
    buttonType: "button" | "submit" | "reset";
    deleteButton?: ReactElement;
    children: ReactElement | ReactElement[];
}

const GenericFormBasic: React.FC<IProps> = ({
    initialValues,
    handleSubmit,
    handleCancel,
    buttonType,
    deleteButton,
    children,
}) => {
    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={handleSubmit}>

            {({ handleSubmit }) =>
                (<Form onSubmit={handleSubmit} className="custom-form">
                    
                    {children}

                    <div className="mt-5 position-relative">
                        <CustomButton text="Save" type={buttonType || "button"} classes="primary pe-3"></CustomButton>
                        <span className="link-button" onClick={handleCancel}>Cancel</span>
                        {initialValues.id && deleteButton}
                    </div>
                    
                </Form>)}
        </Formik>);
}

export default observer(GenericFormBasic);