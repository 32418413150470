import React, { ReactElement } from "react";
import { observer } from "mobx-react-lite";

interface IProps {
    errorMessage?: string;
    children?: ReactElement | ReactElement[];
}

const FormValidationError: React.FC<IProps> = ({ errorMessage, children }) => {
    return (<div>
        <p><small className="header-label-light">{errorMessage || "Please enter a valid input"}</small></p>
        {children}
    </div>);
}

export default observer(FormValidationError);