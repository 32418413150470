import React from "react";
import { observer } from "mobx-react-lite";
import { Link, useLocation } from "react-router-dom";

interface ILinkBasic {
    url: string;
    text: string;
}

interface IProps {
    links: ILinkBasic[];
}

const MembersSubNav: React.FC<IProps> = ({ links }) => {

    const { pathname } = useLocation();

    return (
        <ul className="sub-nav nav">
            {links && links.map(link =>
                <li key={link.url} className="nav-item">
                    <Link to={link.url} className={pathname === link.url ? "nav-link active" : "nav-link"}>{link.text}</Link>
                </li>)}
        </ul>);
}

export default observer(MembersSubNav);