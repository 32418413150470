import React from "react";
import { observer } from "mobx-react-lite";

import Badge from "react-bootstrap/esm/Badge";

import IActivityLogEntry from "../../../../../../app/models/features/time-tracking/IActivityLogEntry";

interface IProps {
    entry: IActivityLogEntry;
    onClick: () => void;
}

const ActivityLogEntryDashboardListItem: React.FC<IProps> = ({
    entry,
    onClick,
}) => {
    return (
        <div
            className="activity-log-entry clickable block-link block-list-item position-relative"
            onClick={onClick}>
            <div className="start-time me-5">
                <span>{entry.startTimeText}</span>
            </div>
            <div className="activity-details ms-5">
                <div className="activity-type">
                    <Badge pill>{entry.activityTypeText}</Badge>
                </div>
                <span className="activity-name">{entry.name}</span>
                {entry.tags && entry.tags.length &&
                    <div className="activity-tags">
                        {entry.tags.map(t => <Badge key={t} className="me-2">{t}</Badge>)}
                    </div>}
                
            </div>
            <div className="duration position-absolute start-0 bottom-0 ps-1 pb-3">
                <span>{entry.durationText}</span>
            </div>
        </div>);
}

export default observer(ActivityLogEntryDashboardListItem);