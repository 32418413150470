import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Link, useLocation } from "react-router-dom";

import { FaBars, FaPowerOff, FaTimes } from "react-icons/fa";

import MembersTopNavMenuItems from "./MembersTopNavMenuItems";

import logo from "../../logo.png";

function MembersTopNavVertical() {

    const [showMenu, setShowMenu] = useState(false);

    const location = useLocation();

    useEffect(() => {
        setShowMenu(false);
    }, [location.pathname]);

    return (<nav className="top-nav vertical">
            <div className="menu-icon" onClick={() => setShowMenu(!showMenu)}>
                {showMenu ? <FaTimes /> : <FaBars />}
            </div>
            {showMenu &&
            <div className="custom-nav custom-shadow">
                <Link to="/"><img className="logo" src={logo} alt="Yodativity" /></Link>
                <ul>
                    <MembersTopNavMenuItems />
                </ul>
                <div className="account-buttons">
                    <Link to="/">
                        <span></span>
                    </Link>
                    <Link to="/logout">
                        <FaPowerOff />
                        <span>log out</span>
                    </Link>
                </div>
            </div>}
        </nav>);
}

export default observer(MembersTopNavVertical);