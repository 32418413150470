import React, { useState } from "react";
import { observer } from "mobx-react-lite";

import IEnumDetails from "../../../../../app/common/enums/helpers/IEnumDetails";
import ISingleDayTargets from "../../../../../app/models/features/time-tracking/ISingleDayTargets";

import activityTypes from "../../../../../app/common/enums/activityTypes";

import DatePickerSection from "../overview/date-picker-section/DatePickerSection";
import SingleDayTargetsForm from "./SingleDayTargetsForm";
import SingleDayTargetsList from "./SingleDayTargetsList";

const Targets = () => {

    const { periodText, weekCommencingDate } = {
        periodText: "23 May 2022 to 30 May 2022",
        weekCommencingDate: new Date(2022, 4, 23),
    };

    const { selectedActivityTypes, monday } = {
        selectedActivityTypes: [
            activityTypes.PRODUCTIVE,
            activityTypes.DISTRACTIONS,
            activityTypes.HOBBIES_AND_RECREATION,
            activityTypes.ERRANDS_AND_CHORES,
        ] as IEnumDetails[],
        monday: {
            dayOfTheWeek: "Monday",
            dateText: "23 May 2022",
            durations: [
                {
                    id: "1",
                    activityType: activityTypes.PRODUCTIVE,
                    targetText: "1h 1m",
                },
                {
                    id: "2",
                    activityType: activityTypes.DISTRACTIONS,
                    targetText: "2h 20m",
                },
                {
                    id: "3",
                    activityType: activityTypes.ERRANDS_AND_CHORES,
                    targetText: "4h 44m",
                },
                {
                    id: "4",
                    activityType: activityTypes.HOBBIES_AND_RECREATION,
                    targetText: "3h 30m",
                },
            ]
        } as ISingleDayTargets,
    }

    const [showEditModal, setShowEditModal] = useState(false);

    const handleOnClick = () => setShowEditModal(!showEditModal);
    
    const handleCancel = () => setShowEditModal(false);
    const handleSubmit = (e: any) => {
        console.log(e)
        setShowEditModal(false);
    };

    return (<>
        <DatePickerSection overviewPeriodText={periodText} weekCommencingDate={weekCommencingDate} />

        <SingleDayTargetsList
            selectedActivityTypes={selectedActivityTypes}
            monday={monday}
            handleOnClick={handleOnClick} />

        <SingleDayTargetsForm
            selectedActivityTypes={selectedActivityTypes}
            showEditModal={showEditModal}
            handleCancel={handleCancel}
            handleSubmit={handleSubmit} />
    </>);
}

export default observer(Targets);