import React from "react";
import { observer } from "mobx-react-lite";

import { FaPen } from "react-icons/fa";

import ISingleDayTargets from "../../../../../app/models/features/time-tracking/ISingleDayTargets";

import { sortedDurations } from "../../../../../app/common/helpers/timeTrackingHelper";

interface IProps {
    handleOnClick: () => void;
}

const SingleDayTargets: React.FC<ISingleDayTargets & IProps> = ({
    dayOfTheWeek,
    dateText,
    durations,
    handleOnClick,
}) => {

    return (
        <tr>
            <td>
                <div>{dayOfTheWeek}</div>
                <div>{dateText}</div>
            </td>
            {sortedDurations(durations).map(d => <td key={d.id}>{d.targetText}</td>)}
            <td className="clickable" onClick={handleOnClick}><FaPen /></td>
        </tr>);
};

export default observer(SingleDayTargets);