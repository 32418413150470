import React from "react";
import { observer } from "mobx-react-lite";
import { FaPlus } from "react-icons/fa";

interface IProps {
    handleClick: () => void;
}

const CreateButton: React.FC<IProps> = ({ handleClick }) => {
    return (
        <div className="mb-4">
            <span className="add-button clickable" onClick={handleClick}><FaPlus /></span>
        </div>);
}

export default observer(CreateButton)