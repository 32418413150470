import React from "react";
import { observer } from "mobx-react-lite";
import { Link, Outlet } from "react-router-dom";

import MembersSubNav from "../../../nav/sub-nav/MembersSubNav";

const SettingsDashboard = () => {

    const links = [
        { url: "/settings/activities", text: "Activities" },
    ];
    
    return (<>
        <Link to="/">Back</Link>
        <h1>Settings</h1>

        <MembersSubNav links={links} />

        <Outlet />
    </>);
}

export default observer(SettingsDashboard);