import React, { useEffect } from "react";

import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

import { useStore } from "../../../app/stores/store";

import FormValidationError from "../../../app/layout/form-fields/FormValidationError";

import logo from "../../../logo.png";
import "../login.css";

function Login () {
    const { userStore, navigationStore } = useStore();

    let validateAllBtnRef:any = null;

    useEffect(() => {
        
        navigationStore.setUserIsOnLoginScreen(true);
        
        setTimeout(() => {

            if(validateAllBtnRef)
            {
                validateAllBtnRef.click();
            }
        }, 250);
    });


    return (
        <div className="login-container">
            <div className="form rounded-corners shadow">
                <Link to="/"><img src={logo} alt="Yodativity" /></Link>
                <Formik
                    initialValues={{ email: "", password: "", error: null }}
                    onSubmit={values => userStore.login(values)}
                    validationSchema={Yup.object({
                        email: Yup.string().required().email(),
                        password: Yup.string().required()
                    })}
                >
                    {({ handleSubmit, isSubmitting, errors, isValid, dirty, validateForm }) => (
                        <Form onSubmit={handleSubmit} autoComplete="off" className="form-fields">
                            
                            <div className="form-group">
                                <label>Email</label>    
                                <Field type="email" name="email" />
                                <ErrorMessage name="email" render={() => <FormValidationError errorMessage={errors.email} />} />
                            </div>

                            <div className="form-group">
                                <label>Password</label>    
                                <Field type="password" name="password"/>
                                <ErrorMessage name="password" render={() => <FormValidationError errorMessage={errors.password} />} />
                            </div>

                            <ErrorMessage
                                name="error"
                                render={() => <FormValidationError errorMessage={errors.error} />}
                            />

                            <button style={{display:"none"}}
                                type="button"
                                ref={input => {
                                    // assigns a reference so we can trigger it later
                                    validateAllBtnRef = input;
                                }}
                                onClick={() => validateForm().then(() => false)}
                            >
                                Validate All
                            </button>

                            <button type="submit" className="primary" disabled={!isValid || !dirty || isSubmitting}>
                                {isSubmitting ? "Logging in..." : "Log in"}
                            </button>
                            <Link to="/signup" className="login-alternative">Or create account</Link>
                            {userStore.logInError && <FormValidationError errorMessage="Sorry, we couldn't log you in. Please check your details are correct. Or contact us for help accessing your account." />}
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default observer(Login);