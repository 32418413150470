import { makeAutoObservable, runInAction } from "mobx";

import IActivity from "../../models/features/settings/IActivity";
import IDetailsBasic from "../../models/shared/IDetailsBasic";

import apiHelpers from "../../api/apiHelpers";
import errorHandler from "../../common/errorHandler";

export default class ActivityStore {
    constructor() {
        makeAutoObservable(this);
    }

    defaultActivity: IActivity = {
        id: undefined,
        name: undefined,
        activityType: undefined,
    };

    activityRegistry : Map<string, IDetailsBasic> = new Map();
    activity : IActivity  = this.defaultActivity;

    loading = false;

    get activities() {
        return Array.from(this.activityRegistry.values());
    }

    setLoading = (loading: boolean) => {
        runInAction(() => {
            this.loading = loading;
        })
    }

    loadActivities = async () => {
        this.setLoading(true);

        try {
            this.clearActivities();
            
            const activities = await apiHelpers.activities.list();
            
            this.clearSelectedActivity();

            runInAction(() => {
                activities.forEach(e => this.activityRegistry.set(e.id!, e));
            });
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    loadActivity = async (id: string) => {
        this.setLoading(true);

        try {
            const activity = await apiHelpers.activities.details(id);

            runInAction(() => {
                this.activity = activity;
            });
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    createActivity = async (activity: IActivity) => {
        this.setLoading(true);

        try {
            await apiHelpers.activities.create(activity);
            await this.loadActivities();

        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    updateActivity = async (activity: IActivity) => {
        this.setLoading(true);

        try {
            await apiHelpers.activities.update(activity);
            await this.loadActivities();

        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    deleteActivity = async (id: string) => {
        this.setLoading(true);

        try {
            await apiHelpers.activities.delete(id);
            await this.loadActivities();
            this.activityRegistry.delete(id);
            
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    clearSelectedActivity = () => {
        runInAction(() => {
            this.activity = this.defaultActivity;
        })
    }

    clearActivities = () => {
        this.activityRegistry.clear();
    }
}