import React from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

import Dropdown from "react-bootstrap/esm/Dropdown";
import { FaUser } from "react-icons/fa";

import MembersTopNavMenuItems from "./MembersTopNavMenuItems";

import logo from "../../logo.png";

function MembersTopNavHorizontal() {

    return (<nav className="top-nav horizontal">
            <Link to="/"><img src={logo} alt="Yodativity" /></Link>
            <ul>
                <MembersTopNavMenuItems />
                <li>
                    <Dropdown>
                        <Dropdown.Toggle className="account-menu-drop-down" id="account-menu-drop-down">
                            <FaUser />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="#/action-2"><Link className="nav-link account-icon" to="/logout">Log out</Link></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </li>
            </ul>
        </nav>);
}

export default observer(MembersTopNavHorizontal);