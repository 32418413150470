import React from "react";
import { observer } from "mobx-react-lite";

import IActivityLogEntrySummary from "../../../../../../app/models/features/time-tracking/IActivityLogEntrySummary";

import ActivityLogEntrySummary from "./ActivityLogEntrySummary";
import CalendarPeriodOverviewNoData from "../CalendarPeriodOverviewNoData";

interface IProps {
    entrySummaries: IActivityLogEntrySummary[] | undefined;
}

const ActivityLogEntrySummaryList: React.FC<IProps> = ({ entrySummaries }) => {

    if (!(entrySummaries && entrySummaries.length))
        return (<CalendarPeriodOverviewNoData />);

    return (<>
        {entrySummaries.map(e => (<ActivityLogEntrySummary key={e.id} summary={e} />))}
    </>);
};

export default observer(ActivityLogEntrySummaryList);