import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

import { useStore } from "../../../app/stores/store";

const LogoutForm = () => {
    const { userStore } = useStore();
    const navigate = useNavigate();
    
    useEffect(() => {
        userStore.logout();
        navigate("/login");
    }, [userStore, navigate]);

    return (
        <div>Goodbye!</div>
    )
}

export default observer(LogoutForm);