import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";

import D3Chart from "../helpers/D3Chart";
import { useStore } from "../../../stores/store";


interface IProps {
    startDate: Date;
}

const GenericChartBasic : React.FC<IProps> = ({ startDate }) => {

    const chartArea = useRef<HTMLDivElement | null>(null);
    const [chart, setChart] = useState<D3Chart | null>(null);

    const { activityEntryStore, activityReportsStore } = useStore();

    useEffect(() => {

        if (!activityReportsStore.entriesSummary) {
          activityReportsStore.loadEntriesSummary(startDate, undefined);
        }

        if (!chart) {
            setChart(new D3Chart(chartArea, activityReportsStore.entriesSummary?.entryTotals || []));
        } else {
            chart.update(activityReportsStore.entriesSummary?.entryTotals || []);
        }
    }, [
        chart,
        startDate,
        activityEntryStore,
        activityReportsStore,
        activityReportsStore.entriesSummary?.entryTotals,
    ])
    

    return (<div className="chart-area mb-5" ref={chartArea}></div>);
}

export default observer(GenericChartBasic);