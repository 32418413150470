import axios, { AxiosResponse } from "axios";

import IActivity from "../models/features/settings/IActivity";
import IActivityLogEntry from "../models/features/time-tracking/IActivityLogEntry";
import IActivityLogEntryFormProps from "../models/features/time-tracking/IActivityLogEntryFormProps";
import IDetailsBasic from "../models/shared/IDetailsBasic";
import IResult from "../models/responses/IResult";
import IUser from "../models/users/IUser";
import IUserLoginFormValues from "../models/users/IUserLoginFormValues";
import IUserRegistrationFormValues from "../models/users/IUserRegistrationFormValues";

import { store } from "../stores/store";


axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(config => {
  const token = store.commonStore.token;
  
  if (token && config.headers) {
    config.headers.Authorization = `bearer ${token}`;
  }

  return config;
});

const responseBody = (response: AxiosResponse) => response.data;
const ACCOUNT_ENDPOINT = "account";
const ACTIVITY_ENDPOINT = "activities";
const ACTIVITY_ENTRY_ENDPOINT = "activityentries";
const ACTIVITY_REPORTS_ENDPOINT = "activityreports";

const requests = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
  postForm: (url: string, file: Blob) => {
    let formData = new FormData();
    formData.append('File', file);
    return axios
      .post(url, formData, {
        headers: { 'Content-type': 'multipart/form-data' }
      })
      .then(responseBody);
  }
};

const account = {
  currentUser: () => requests.get<IUser>(`/${ACCOUNT_ENDPOINT}`),
  login: (user: IUserLoginFormValues) => requests.post<IUser>(`/${ACCOUNT_ENDPOINT}/login`, user),
  register: (user: IUserRegistrationFormValues) => requests.post<IResult<string>>(`/${ACCOUNT_ENDPOINT}/register`, user),
  refreshToken: () => requests.post<IUser>(`/${ACCOUNT_ENDPOINT}/refreshToken`, {}),
  confirmEmail: (token: string, email: string) => requests.post<void>(`${ACCOUNT_ENDPOINT}/confirmEmail?token=${token}&email=${email}`, {}),
  resendEmail: (email: string) => requests.get(`${ACCOUNT_ENDPOINT}/resendEmailConfirmationLink?email=${email}`),
};

const activityEntries = {
  list: (
    date: string | undefined,
    activityType: number | undefined
  ): Promise<IActivityLogEntry[]> =>
    requests.post(`/${ACTIVITY_ENTRY_ENDPOINT}/list`, { date, activityType }),
  details: (id: string) => requests.get(`/${ACTIVITY_ENTRY_ENDPOINT}/${id}`),
  create: (entry: IActivityLogEntryFormProps) => requests.post(`/${ACTIVITY_ENTRY_ENDPOINT}`, entry),
  update: (entry: IActivityLogEntryFormProps) =>
    requests.put(`/${ACTIVITY_ENTRY_ENDPOINT}/${entry.id}`, entry),
  delete: (id: string) => requests.del(`/${ACTIVITY_ENTRY_ENDPOINT}/${id}`),
};

const activities = {
  list: (): Promise<IDetailsBasic[]> =>
    axios.get(`/${ACTIVITY_ENDPOINT}/list`).then(responseBody),
  details: (id: string) => requests.get(`/${ACTIVITY_ENDPOINT}/${id}`),
  create: (entry: IActivity) => requests.post(`/${ACTIVITY_ENDPOINT}`, entry),
  update: (entry: IActivity) =>
    requests.put(`/${ACTIVITY_ENDPOINT}/${entry.id}`, entry),
  delete: (id: string) => requests.del(`/${ACTIVITY_ENDPOINT}/${id}`),
};

const activityReports = {
  entriesSummary: (
      startDate: string | undefined,
      activityType: number | undefined) => 
        requests.post(`/${ACTIVITY_REPORTS_ENDPOINT}/summary`, { startDate, activityType }),
}

const apiHelpers = {
  account,
  activityEntries,
  activities,
  activityReports,
};


export default apiHelpers;