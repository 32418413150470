import dates from "../constants/dates";
import { addDays } from "date-fns";

interface IDateHelper {
    formatWeekPeriod: (date: Date) => string;
    formatDate: (dateAsString: string | undefined) => string | undefined;
}


export const formatWeekPeriod = (date: Date) => {

    const lastDayOfTheWeek = addDays(date, dates.TOTAL_DAYS_IN_A_WEEK - 1);

    const format = (d: Date) =>
        d.toLocaleDateString('en-gb', {  day: "numeric", month:"long", year:"numeric"});

    return `${format(date)} to ${format(lastDayOfTheWeek)}`;
};

export const formatDate = (dateAsString: string | undefined) => {

    if (!dateAsString) return dateAsString;
    
    const date = new Date(dateAsString);
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}T${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;

    return formattedDate;
}

const dateFormatter: IDateHelper = {
    formatWeekPeriod,
    formatDate,
};

export default dateFormatter;