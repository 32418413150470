import IEnumDetails from "./helpers/IEnumDetails";

interface IActivityTypes {
    PRODUCTIVE: IEnumDetails,

    DISTRACTIONS: IEnumDetails,

    HOBBIES_AND_RECREATION: IEnumDetails,

    ERRANDS_AND_CHORES: IEnumDetails,

    HEALTH_AND_WELLBEING: IEnumDetails,
}

const activityTypes: IActivityTypes = {
    PRODUCTIVE: { name: "Productive", label: "Productive", value: 0 },

    DISTRACTIONS: { name: "Distractions", label: "Distractions", value: 1 },

    HOBBIES_AND_RECREATION: { name: "HobbiesAndRecreation", label: "Hobbies & recreation", value: 2 },

    ERRANDS_AND_CHORES: { name: "ErrandsAndChores", label: "Errands & chores", value: 3 },

    HEALTH_AND_WELLBEING: { name: "HealthAndWellbeing", label: "Health & wellbeing", value: 4}
};

export default activityTypes;