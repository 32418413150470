import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { addDays } from "date-fns";

import dates from "../../../../../../app/common/constants/dates";
import datesHelper from "../../../../../../app/common/helpers/datesHelper";
import { useStore } from "../../../../../../app/stores/store";

import DatePickerSectionCalendar from "./DatePickerSectionCalendar";
import DatePickerSectionControls from "./DatePickerSectionControls";
import GenericModal from "../../../../../../app/layout/modals/GenericModal";

import "react-datepicker/dist/react-datepicker.css";

interface IProps {
    overviewPeriodText: string | undefined;
    weekCommencingDate: Date;
}

const DatePickerSection: React.FC<IProps> = ({ overviewPeriodText, weekCommencingDate }) => {

    const { activityReportsStore } = useStore();

    const [selectedWeek, setSelectedWeek] = useState(weekCommencingDate); 
    const [selectedWeekText, setSelectedWeekText] = useState(overviewPeriodText);
    const [isOpen, setIsOpen] = useState(false); 

    const handleSelectPreviousWeek = () => {
        setSelectedWeek(addDays(selectedWeek, -dates.TOTAL_DAYS_IN_A_WEEK));
        setIsOpen(false);
    };

    const handleSelectFollowingWeek = () => {
        setSelectedWeek(addDays(selectedWeek, dates.TOTAL_DAYS_IN_A_WEEK));
        setIsOpen(false);
    };

    const handleChange = (e: Date) => {
        setIsOpen(!isOpen);
        setSelectedWeek(e);
    };

    const handleClick = (e: React.ChangeEvent<any>) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };    

    useEffect(() => {
        setSelectedWeekText(datesHelper.formatWeekPeriod(selectedWeek));
        activityReportsStore.setSelectedStartDate(selectedWeek);
    }, [
        activityReportsStore,
        selectedWeek,
    ])

    return (<>
        <DatePickerSectionControls
            selectedWeekText={selectedWeekText}
            handleClick={handleClick}
            handleSelectPreviousWeek={handleSelectPreviousWeek}
            handleSelectFollowingWeek={handleSelectFollowingWeek} />

        <GenericModal
            title="Pick a date"
            showModal={isOpen}
            noFooter={true}
            handleCancel={() => setIsOpen(false)}
            handleConfirmed={handleChange}
        >
            <DatePickerSectionCalendar selectedWeek={selectedWeek} handleChange={handleChange} />
            <div className="link-button mt-3" onClick={() => setIsOpen(false)}>Cancel</div>
        </GenericModal>
    </>);
}

export default observer(DatePickerSection);