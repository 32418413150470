import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../../app/stores/store";

import IActivity from "../../../../../app/models/features/settings/IActivity";

import ActivitySettingsForm from "../forms/ActivitySettingsForm";
import CreateButton from "../../../../../app/layout/common-components/CreateButton";
import Loading from "../../../../../app/layout/Loading";
import NoResultsFound from "../../../../../app/layout/NoResultsFound";

const ActivitySettingsDashboard = () => {

    const { activityStore } = useStore();

    const [showModal, setShowModal] = useState(false);

    const handleSelectActivity = async (id: string | undefined) => {
    
        if (id) await activityStore.loadActivity(id);
        
        setShowModal(true);
    }

    const handleConfirmedDelete = async () => {
        
        if (!activityStore.activity?.id) return;
        
        await activityStore.deleteActivity(activityStore.activity?.id);
        setShowModal(false);
    }

    const handleSubmit = async (activity: IActivity) => {

        const apiCall = activity.id ? activityStore.updateActivity : activityStore.createActivity;

        await apiCall(activity);

        setShowModal(false);
    };

    const activityForm = <ActivitySettingsForm
        initialValues={activityStore.activity}    
        showModal={showModal}
        setShowModal={setShowModal}
        handleConfirmedDelete={handleConfirmedDelete}
        handleCancel={() => setShowModal(false)}
        handleSubmit={handleSubmit} />;

    useEffect(() => {

        if (!activityStore.activities.length) activityStore.loadActivities();

        if (!showModal) activityStore.clearSelectedActivity();

    }, [showModal, activityStore, activityStore.activity?.id]);

    if (!(activityStore.activities && activityStore.activities.length))
        return <NoResultsFound message="There are no activities set up yet. Click + to set up a new activity">
            <CreateButton handleClick={() => setShowModal(true)} />
            {activityForm}
        </NoResultsFound>;
 
    return (<>
        {activityStore.loading && <Loading />}

        <div className="section-card">
            <CreateButton handleClick={() => setShowModal(true)} />

                {activityStore.activities.map(a =>
                    <div
                        key={a.id}
                        className="clickable block-link block-list-item"
                        onClick={() => handleSelectActivity(a.id)}
                    >
                        {a.name}
                    </div>)}
        </div>

        {activityForm}
    </>);
}

export default observer(ActivitySettingsDashboard);