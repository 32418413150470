import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

import { FaCog } from "react-icons/fa";

import { defaultEntry, submitNewEntryForm } from "../helpers/entryFormHelper";
import { toOptionList } from "../../../../../../app/models/form-fields/formFieldHelper";
import { useStore } from "../../../../../../app/stores/store";

import ActivityLogEntryForm from "../../../activities/log-entries/details/ActivityLogEntryForm";
import ActivityTypeForm from "./ActivityTypeForm";
import CreateButton from "../../../../../../app/layout/common-components/CreateButton";

const CustomActions = () => {

    const { activityEntryStore, activityReportsStore, activityStore } = useStore();

    const [showModal, setShowModal] = useState(false);
    
    const handleSubmit = submitNewEntryForm(
        activityEntryStore,
        activityReportsStore,
        activityStore,
        () => setShowModal(false));

    return (
        <>
            <div className="custom-actions">
                <CreateButton handleClick={() => setShowModal(true)} />
                <div className="quick-access">
                    <Link to="/settings/activities" className="targets no-style"><FaCog /></Link>
                    <ActivityTypeForm />
                </div>
            </div>

            <ActivityLogEntryForm
                initialValues={defaultEntry}
                activities={toOptionList(activityStore?.activities)}
                showModal={showModal}
                setShowModal={setShowModal}
                handleCancel={() => setShowModal(false)}
                handleSubmit={handleSubmit} />
        </>
    );
}

export default observer(CustomActions);