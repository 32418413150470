import React from "react";
import { observer } from "mobx-react-lite";

import Table from "react-bootstrap/esm/Table";

import IEnumDetails from "../../../../../app/common/enums/helpers/IEnumDetails";
import ISingleDayTargets from "../../../../../app/models/features/time-tracking/ISingleDayTargets";

import { sortedActivityTypes } from "../../../../../app/common/helpers/timeTrackingHelper";

import SingleDayTargets from "./SingleDayTargets";

interface IProps {
    selectedActivityTypes: IEnumDetails[];
    monday: ISingleDayTargets;
    handleOnClick: () => void;
}

const SingleDayTargetsList: React.FC<IProps> = ({
    selectedActivityTypes,
    monday,
    handleOnClick,
}) => {
    return (
        <Table hover>
            <thead>
                <tr>
                    <th>Date</th>
                    {sortedActivityTypes(selectedActivityTypes).map(at => <th key={at.value}>{at.label}</th>)}
                    <th></th>
                </tr>
            </thead>

            <tbody>
                <SingleDayTargets
                    dateText={monday.dateText}
                    dayOfTheWeek={monday.dayOfTheWeek}
                    durations={monday.durations}
                    handleOnClick={handleOnClick} />
            </tbody>
        </Table>);
}

export default observer(SingleDayTargetsList);