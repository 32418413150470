import React from "react";
import * as d3 from "d3";

import ICalendarPeriodTotals from "../../../models/features/time-tracking/ICalendarPeriodTotals";

const MARGIN = { TOP: 10, BOTTOM: 60, LEFT: 70, RIGHT: 10 }
const WIDTH = 800 - MARGIN.LEFT - MARGIN.RIGHT;
const HEIGHT = 500 - MARGIN.TOP - MARGIN.BOTTOM;
const DOMAIN = { 
    MINIMUM: {
        VALUE: 230,
        OFFSET_FACTOR: 0.95,
    }, 
    MAXIMUM: {
        VALUE: 275,
        OFFSET_FACTOR: 1.01,
    },
};
const TRANSITIONS = { DURATION: 500 };
const ERROR_CODE = { MISSING: -1 };

const COLORS = { 
    PRIMARY_COLOR_VERY_LIGHT: "#e66666",
    SECONDARY_COLOR: "#755555",
};

export default class D3Chart {

    svg: d3.Selection<SVGGElement, unknown, null, undefined> | null = null;
    xLabel: d3.Selection<SVGTextElement, unknown, null, undefined> | null = null;
    xAxisGroup: d3.Selection<SVGGElement, unknown, null, undefined> | null = null;
    yAxisGroup: d3.Selection<SVGGElement, unknown, null, undefined> | null = null;


    constructor(chartArea: React.RefObject<HTMLDivElement>, data: ICalendarPeriodTotals[]) {

        if (!chartArea.current) throw Error("Chart area is missing");
        
        chartArea.current.innerHTML = "";
        
        this.svg = d3.select(chartArea.current)
            .append("svg")
                .attr("width", WIDTH + MARGIN.LEFT + MARGIN.RIGHT)
                .attr("height", HEIGHT + MARGIN.TOP + MARGIN.BOTTOM)
            .append("g")
                .attr("transform", `translate(${MARGIN.LEFT}, ${MARGIN.TOP})`);

        this.xLabel = this.svg.append("text")
            .attr("x", WIDTH / 2)
            .attr("y", HEIGHT + 50)
            .attr("text-anchor", "middle");

        this.svg.append("text")
            .attr("x", -(HEIGHT / 2))
            .attr("y", -50)
            .attr("text-anchor", "middle")
            .text("Number of hours")
                .attr("transform", "rotate(-90)")
                .style("fill", COLORS.PRIMARY_COLOR_VERY_LIGHT);

        this.xAxisGroup = this.svg.append("g")
            .attr("transform", `translate(0, ${HEIGHT})`);

        this.yAxisGroup = this.svg.append("g");

        this.update(data);
    }

    update(data: ICalendarPeriodTotals[]) {

        if (!this.svg) throw Error("Chart area is missing");

        this.xLabel
            ?.text("Date")
            .style("fill", COLORS.PRIMARY_COLOR_VERY_LIGHT);

        const y = d3.scaleLinear()
            .domain([
                0,
                (d3.max(data, d =>  d.durationTotal) || DOMAIN.MAXIMUM.VALUE) * DOMAIN.MAXIMUM.OFFSET_FACTOR,
            ])
            .range([HEIGHT, 0])

        const x = d3.scaleBand()
            .domain([
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday",
            ])
            .range([0, WIDTH])
            .padding(0.4)

        const xAxisCall = d3.axisBottom(x)
        this.xAxisGroup?.transition().duration(TRANSITIONS.DURATION).call(xAxisCall)

        const yAxisCall = d3.axisLeft(y)
        this.yAxisGroup?.transition().duration(TRANSITIONS.DURATION).call(yAxisCall)

        // DATA JOIN
        const rects = this.svg.selectAll("rect")
            .data(data)

        // EXIT
        rects.exit()
            .transition().duration(TRANSITIONS.DURATION)
                .attr("height", 0)
                .attr("y", HEIGHT)
            .remove();

        // UPDATE
        rects.transition().duration(TRANSITIONS.DURATION)
            .attr("x", d => x(d.category) || ERROR_CODE.MISSING)
            .attr("y", d => y(d.durationTotal) || ERROR_CODE.MISSING)
            .attr("width", x.bandwidth)
            .attr("height", d => HEIGHT - y(d.durationTotal));

        // ENTER
        rects.enter().append("rect")
            .attr("x", d => x(d.category) || ERROR_CODE.MISSING)
            .attr("width", x.bandwidth)
            .attr("fill", COLORS.SECONDARY_COLOR)
            .attr("y", HEIGHT)
            .transition().duration(TRANSITIONS.DURATION)
                .attr("height", d => HEIGHT - y(d.durationTotal))
                .attr("y", d => y(d.durationTotal));
    }
}