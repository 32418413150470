import { AxiosError } from "axios";
import { makeAutoObservable, runInAction } from "mobx";

import IResult from "../models/responses/IResult";
import IUser from "../models/users/IUser";
import IUserLoginFormValues from "../models/users/IUserLoginFormValues";
import IUserRegistrationFormValues from "../models/users/IUserRegistrationFormValues";

import configNames from "../common/constants/configNames";

import apiHelpers from "../api/apiHelpers";
import errorHandler from "../common/errorHandler";
import history from "../common/history";
import { store } from "./store";

export default class UserStore {
    user: IUser | null = null;
    refreshTokenTimeout: any;
    thereWasALoginError: string = "";

    constructor() {
        makeAutoObservable(this);
    }

    get isLoggedIn () {
        return !!this.user;
    }
    
    get logInError () {
        return !!this.user ? "" : this.thereWasALoginError;
    }

    login = async (credentials: IUserLoginFormValues) => {
        try {
            const user = await apiHelpers.account.login(credentials);
            store.commonStore.setToken(user.token);
            runInAction(() => this.user = user);
            this.startRefreshTokenTimer(user);
            history.push("/");
        } catch(error) {
            this.thereWasALoginError = "Invalid email or password.";
            errorHandler.handleError(error);
        }
    }

    logout = () => {
        this.stopRefreshTokenTimer();
        store.commonStore.setToken(null);
        window.localStorage.removeItem(configNames.TOKEN_NAME);
        this.thereWasALoginError = "";
        this.user = null;
        history.push("/");
    }

    getUser = async () => {
        try {
            const user = await apiHelpers.account.currentUser();
            store.commonStore.setToken(user.token);
            runInAction(() => this.user = user);
            this.startRefreshTokenTimer(user);
        } catch(error) {
            errorHandler.handleError(error);
        }
    }

    register = async (
        newUser: IUserRegistrationFormValues,
        navigate: (url: string) => void) => {
        try {
            await apiHelpers.account.register(newUser);

            navigate(`/account/registrationSuccessful?email=${newUser.email}`);

        } catch(error) {
            const registrationErrors = (error as AxiosError).response?.data as IResult<string>;

            if (!registrationErrors) {
                this.thereWasALoginError = "Invalid email or password";
                return;
            }

            this.thereWasALoginError = registrationErrors.errorMessage;
        }
    }

    refreshToken = async () => {
            this.stopRefreshTokenTimer();

            try {
                const user = await apiHelpers.account.refreshToken();
                runInAction(() => {
                    this.user= user;
                })
                store.commonStore.setToken(user.token);
                this.startRefreshTokenTimer(user);
            } catch (error) {
                errorHandler.handleError(error);
            }
    }

    private startRefreshTokenTimer(user: IUser) {
        const jwtToken = JSON.parse(atob(user.token.split(".")[1]));
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (30 * 1000);
        this.refreshTokenTimeout = setTimeout(this.refreshToken, timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }
}