import React, { ReactElement } from "react";

import { observer } from "mobx-react-lite";

import "./style/no-results.css";

interface IProps {
    message?: string;
    children?: ReactElement | ReactElement[];
}

const NoResultsFound: React.FC<IProps> = ({ message, children }) => {

    return <>
        <div className="no-results-section">
            <span>{message || "No results found"}</span>
            {children}
        </div>
    </>
}

export default observer(NoResultsFound);