import React from "react";
import { observer } from "mobx-react-lite";

import IEnumDetails from "../../../../../app/common/enums/helpers/IEnumDetails";

import { sortedActivityTypes } from "../../../../../app/common/helpers/timeTrackingHelper";

import GenericFormBasic from "../../../../../app/layout/form-fields/GenericFormBasic";
import GenericModal from "../../../../../app/layout/modals/GenericModal";
import TextInputField from "../../../../../app/layout/form-fields/TextInputField";

interface IProps {
    selectedActivityTypes: IEnumDetails[];
    showEditModal: boolean;
    handleCancel: () => void;
    handleSubmit: (e: any) => void;
}

const SingleDayTargetsForm: React.FC<IProps> = ({
    selectedActivityTypes,
    showEditModal,
    handleCancel,
    handleSubmit,
}) => {

    const dateText = "Monday 23 May 2022";

    return (
        <GenericModal
            title={dateText}
            confirmMessage={"Save"}
            cancelMessage={"Cancel"}
            showModal={showEditModal}
            handleCancel={handleCancel}
            handleConfirmed={handleSubmit}
            noFooter={true}>
                
                <GenericFormBasic
                    initialValues={{ activityType: "", error: null }}
                    handleSubmit={handleSubmit}
                    handleCancel={handleCancel}
                    buttonType="submit" >

                        {sortedActivityTypes(selectedActivityTypes).map(at => 
                            
                            <TextInputField
                                key={at.name}
                                label={at.label}
                                type="number"
                                placeholder="In hours only e.g. 0.5 hours"
                                name={`${at.name}Duration`} />)}
                </GenericFormBasic>

        </GenericModal>
    );
}

export default observer(SingleDayTargetsForm);