import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

import apiHelpers from "../../../app/api/apiHelpers";
import { useQuery } from "../../../app/common/helpers/navigationHelper";

import ResendConfirmationLink from "./ResendConfirmationLink";


const ConfirmEmail = () => {

    const email = useQuery().get("email") as string;
    const token = useQuery().get("token") as string;

    const statusOptions = {
        VERIFYING: "Verifying",
        FAILED: "Failed",
        SUCCESS: "Success",
    };

    const [status, setStatus] = useState(statusOptions.VERIFYING);

    useEffect(() => {
        apiHelpers.account.confirmEmail(token, email)
            .then(() => {
                setStatus(statusOptions.SUCCESS);
            })
            .catch(() => {
                setStatus(statusOptions.FAILED);
            })
    }, [
        statusOptions.SUCCESS, 
        statusOptions.FAILED, 
        token, 
        email
    ]);

    const getBody = () => {
        if (status === statusOptions.VERIFYING) return <p>Verifying ...</p>
        
        if (status === statusOptions.FAILED) return <div>
            <p>Verification failed. You can try resending the confirmation link to your inbox.</p>
            
            <ResendConfirmationLink email={email} />
        </div>
        
        if (status === statusOptions.SUCCESS) return <p>
            Your email has been verified. You can now <Link to="/login">log in</Link> to your account.
        </p>
    }

    return (<>
        <p>Email verification</p>
        {getBody()}
    </>);
}

export default observer(ConfirmEmail);