import IDetailsBasic from "../shared/IDetailsBasic";
import ISelectOption from "./ISelectOption";

export const toOptionList = (items: IDetailsBasic[]): ISelectOption[] => 
    items.map(i =>  ({text: i.name, value: i.id} as ISelectOption))

const formFieldHelper = {
    toOptionList,
};

export default formFieldHelper;