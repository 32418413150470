import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link, Outlet } from 'react-router-dom';

import { FaTwitter } from "react-icons/fa";

function HomeGuest() {
  return (
      <div className="home-guest-container">
        <div className="welcome-section">
            <div className="tagline">Yodativity<br />for your productivity</div>
            <div className="get-started">
                <Link to="/signup" className="call-to-action">
                    <span>Get </span>
                    <span>started</span>
                </Link>
            </div>
            <div className="social-media-links">
                <span>Follow us for all the latest updates</span>
                <div className="icons">
                <a href="https://twitter.com/Yodativity"><FaTwitter /></a>
                </div>
            </div>
        </div>
        <Outlet />
      </div>
  );
}

export default observer(HomeGuest);