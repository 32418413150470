import { makeAutoObservable, runInAction } from "mobx";

import ICalendarPeriodOverview from "../../models/features/time-tracking/ICalendarPeriodOverview";
import IEnumDetails from "../../common/enums/helpers/IEnumDetails";

import apiHelpers from "../../api/apiHelpers";
import errorHandler from "../../common/errorHandler";
import { formatDate } from "../../common/helpers/datesHelper";

export default class ActivityReportsStore {
    constructor() {
        makeAutoObservable(this);
    }

    entriesSummary: ICalendarPeriodOverview | undefined = undefined;
    selectedStartDate: Date | undefined = undefined;
    selectedActivityType: IEnumDetails | undefined = undefined;
    loading = false;

    setLoading = (loading: boolean) => {
        runInAction(() => {
            this.loading = loading;
        })
    }

    setSelectedStartDate = (startDate: Date | undefined) => {
        runInAction(() => {
            this.selectedStartDate = startDate;
        });
    }

    clearSelectedStartDate = () => {
        runInAction(() => {
            this.selectedStartDate = undefined;
        });
    }

    setSelectedActivityType = (activityType: IEnumDetails | undefined) => {
        runInAction(() => {
            this.selectedActivityType = activityType;
        });
    }

    clearSelectedActivityType = () => {
        runInAction(() => {
            this.selectedActivityType = undefined;
        });
    }

    loadEntriesSummary = async (
        startDate: Date | undefined,
        activityType: IEnumDetails | undefined) => {
        this.setLoading(true);

        try {

            const startDateText = startDate ? formatDate(startDate.toISOString()) : undefined;

            const entriesSummary = await apiHelpers.activityReports.entriesSummary(startDateText, activityType?.value);

            entriesSummary.weekCommencingDate = new Date(entriesSummary.weekCommencingDateText);

            runInAction(() => {
                this.entriesSummary = entriesSummary
            });

        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }
}