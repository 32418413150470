import React from "react";
import { ErrorMessage, useField } from "formik";
import { observer } from "mobx-react-lite";

import { Field } from "formik";
import FormValidationError from "./FormValidationError";

interface IProps {
    label?: string;
    name: string;
    type: string;
    required?: boolean;
    placeholder?: string;
    errorMessage?: string;
}
const TextInputField = (props: IProps) => {

  const [field] = useField(props.name);

  return (
    <>
      <div className="form-group">
          {props.label && <label>{props.label}</label>} 
          <Field {...field} {...props} />
          <ErrorMessage name={props.name} render={() => <FormValidationError errorMessage={props.errorMessage} />} />
      </div>
    </>
  );
};

export default observer(TextInputField);