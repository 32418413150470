import React from "react";
import { observer } from "mobx-react-lite";

import { FaAngleLeft, FaAngleRight, FaCalendarAlt } from "react-icons/fa";

interface IProps {
    selectedWeekText: string | undefined;
    handleClick: (e: React.ChangeEvent<any>) => void;
    handleSelectPreviousWeek: () => void;
    handleSelectFollowingWeek: () => void;
}

const DatePickerSectionControls: React.FC<IProps> = ({
    selectedWeekText,
    handleClick,
    handleSelectPreviousWeek,
    handleSelectFollowingWeek,
}) => {
    return (
        <div className="date-picker-section">
            <span className="week-picker-arrow clickable" onClick={handleSelectPreviousWeek}><FaAngleLeft /></span>
            <div className="date-picker-button clickable" onClick={handleClick}><FaCalendarAlt /></div>
            <span className="clickable" onClick={handleClick}>{selectedWeekText}</span>
            <span className="week-picker-arrow clickable" onClick={handleSelectFollowingWeek}><FaAngleRight /></span>                
        </div>);
}

export default observer(DatePickerSectionControls);