import { observer } from "mobx-react-lite";
import { Route, Routes } from 'react-router-dom';

import { useStore } from "../../../app/stores/store";

import ConfirmEmail from "../../user/email-confirmation/ConfirmEmail";
import CookiePolicy from '../../../features/legal/CookiePolicy';
import HomeGuest from '../../../features/guest/home/HomeGuest';
import Login from '../../user/forms/Login';
import Logout from '../../../features/user/forms/Logout';
import NotFound from "../../../app/layout/NotFound";
import PrivacyPolicy from '../../../features/legal/PrivacyPolicy';
import RegisterSuccess from "../../user/email-confirmation/RegisterSuccess";
import Signup from '../../../features/user/forms/Signup';
import TermsAndConditions from '../../../features/legal/TermsAndConditions';
import TopNav from "../../../app/nav/TopNav";

function GuestRoutes() {
    const { navigationStore } = useStore();
    
    return (<div className={`${navigationStore.userIsOnLogInScreen ? "member" : "guest"} app-container`}>
        {!navigationStore.userIsOnLogInScreen && <TopNav />}

        <div className="guest-section not-logged-in">
            <div style={!navigationStore.userIsOnLogInScreen ? { marginTop: '2em' } :  { paddingTop: '2em' }}>
                <Routes>
                    <Route path='/' element={<HomeGuest />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/logout" element={<Logout />} />
                    <Route path="/signup" element={<Signup />} />

                    <Route path="/account/confirmEmail" element={<ConfirmEmail />} />
                    <Route path="/account/registrationSuccessful" element={<RegisterSuccess />} />
                    
                    <Route path="/cookie-policy" element={<CookiePolicy />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-and-conditions" element={<TermsAndConditions />} />

                    <Route path="*" element={<NotFound />} />
                    
                </Routes>
            </div>
        </div>
    </div>);
}

export default observer(GuestRoutes);