import React from "react";
import { observer } from "mobx-react-lite";
import { Navigate, useLocation } from "react-router-dom";

interface IProps {
    to: string;
}

const CustomRedirect: React.FC<IProps> = ({ to }) => {
    const location = useLocation();

    return (<Navigate to={to} replace state={{ location }} />);
}

export default observer(CustomRedirect);