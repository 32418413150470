import React, { useState } from "react";
import { observer } from "mobx-react-lite";

import { defaultEntry, submitNewEntryForm } from "./helpers/entryFormHelper";
import { toOptionList } from "../../../../../app/models/form-fields/formFieldHelper";
import { useStore } from "../../../../../app/stores/store";

import ActivityLogEntryForm from "../../activities/log-entries/details/ActivityLogEntryForm";
import CreateButton from "../../../../../app/layout/common-components/CreateButton";
import NoResultsFound from "../../../../../app/layout/NoResultsFound";

const CalendarPeriodOverviewNoData = () => {

    const { activityEntryStore, activityReportsStore, activityStore } = useStore();

    const [showModal, setShowModal] = useState(false);

    const handleSubmit = submitNewEntryForm(
        activityEntryStore,
        activityReportsStore,
        activityStore,
        () => setShowModal(false));

    return (
        <NoResultsFound message="There are no entries for this period. Clear any selected filters. Or click the plus button to add a new entry.">
            <CreateButton handleClick={() => setShowModal(true)} />
            
            <ActivityLogEntryForm
                initialValues={defaultEntry}
                activities={toOptionList(activityStore?.activities)}
                showModal={showModal}
                setShowModal={setShowModal}
                handleCancel={() => setShowModal(false)}
                handleSubmit={handleSubmit} />
        </NoResultsFound>);
}

export default observer(CalendarPeriodOverviewNoData);