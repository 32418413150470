import { makeAutoObservable, runInAction } from "mobx";

import { format } from "date-fns";

import IActivityLogEntry from "../../models/features/time-tracking/IActivityLogEntry";

import apiHelpers from "../../api/apiHelpers";
import errorHandler from "../../common/errorHandler";
import { formatDate } from "../../common/helpers/datesHelper";

export default class ActivityEntryStore {
    constructor() {
        makeAutoObservable(this);
    }

    activityEntryRegistry : Map<string, IActivityLogEntry> = new Map();
    activityEntry : IActivityLogEntry | null = null;
    selectedDate: string | undefined = undefined;
    selectedActivityType: number | undefined = undefined;
    loading = false;

    get activityEntries() {
        return Array.from(this.activityEntryRegistry.values());
    }

    get selectedDateText() {
        return this.selectedDate ? format(new Date(this.selectedDate), "dd MMMM yyyy") : "All entries"
    }

    setLoading = (loading: boolean) => {
        runInAction(() => {
            this.loading = loading;
        })
    }

    setSelectedDate = (date: string | undefined) => {
        runInAction(() => {
            this.selectedDate = date === "all" ? undefined : date;
        });
    }

    clearSelectedDate = () => {
        runInAction(() => {
            this.selectedDate = undefined;
        });
    }

    setSelectedActivityType = (activityType: number | undefined) => {
        runInAction(() => {
            this.selectedActivityType = activityType === 1000 ? undefined : activityType;
        });
    }

    clearSelectedActivityType = () => {
        runInAction(() => {
            this.selectedActivityType = undefined;
        })
    }

    loadActivityEntries = async (date: string | undefined, activityType: number | undefined) => {
        this.setLoading(true);

        try {
            this.clearActivityEntries();
            
            const activityEntries = await apiHelpers.activityEntries.list(date, activityType);
            
            this.clearSelectedActivityEntry();

            runInAction(() => {
                activityEntries.forEach(e => this.activityEntryRegistry.set(e.id!, e));
            });
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    loadActivityEntry = async (id: string) => {
        this.setLoading(true);

        try {
            const activityEntry = await apiHelpers.activityEntries.details(id);

            runInAction(() => {
                this.activityEntry = activityEntry;
            });
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    createActivityEntry = async (activityEntry: IActivityLogEntry) => {
        this.setLoading(true);

        try {
            activityEntry.startTime = formatDate(activityEntry.startTime);
            await apiHelpers.activityEntries.create(activityEntry);
            await this.loadActivityEntries(this.selectedDate, this.selectedActivityType);

        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    updateActivityEntry = async (activityEntry: IActivityLogEntry) => {
        this.setLoading(true);

        try {
            activityEntry.startTime = formatDate(activityEntry.startTime);
            await apiHelpers.activityEntries.update(activityEntry);
            await this.loadActivityEntries(this.selectedDate, this.selectedActivityType);

        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    deleteActivityEntry = async (id: string) => {
        this.setLoading(true);

        try {
            await apiHelpers.activityEntries.delete(id);
            await this.loadActivityEntries(this.selectedDate, this.selectedActivityType);
            this.activityEntryRegistry.delete(id);
            
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    clearSelectedActivityEntry = () => {
        this.activityEntry = null;
    }

    clearActivityEntries = () => {
        this.activityEntryRegistry.clear();
    }
}