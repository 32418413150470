import React, { useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { ErrorMessage, Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { useStore } from "../../../app/stores/store";

import FormValidationError from "../../../app/layout/form-fields/FormValidationError";

import logo from "../../../logo.png";
import "../login.css";

function Signup () {
    const { userStore, navigationStore } = useStore();
    
    const navigate = useNavigate();
    
    useEffect(() => {
        navigationStore.setUserIsOnLoginScreen(true);   
    }, [navigationStore]);

    return (
        <div className="login-container">
            <div className="form rounded-corners shadow">
                <Link to="/"><img src={logo} alt="Yodativity" /></Link>

                <Formik
                    initialValues={{ firstName: "", lastName: "", email: "", password: "", error: null }}
                    onSubmit={(values, { setErrors }) => userStore.register(values, url => navigate(url)).catch(error => {
                        setErrors({error: error.message})
                    })}
                    validationSchema={Yup.object({
                        firstName: Yup.string().required("First name is a required field"),
                        lastName: Yup.string().required("Last name is a required field"),
                        email: Yup.string().required().email(),
                        password: Yup.string().required()
                    })}
                >
                    {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (
                        <Form onSubmit={handleSubmit} autoComplete="off" className="form-fields">

                            <div className="form-group">
                                <div className="long-text">
                                    <p>Create your Yodativity account ... or <Link to="/login" className="login-alternative">log in instead</Link></p>
                                </div>
                                <label>First name</label>    
                                <Field type="text" name="firstName" />
                                <ErrorMessage name="firstName" render={() => <FormValidationError errorMessage={errors.firstName} />} />
                            </div> 
                            <div className="form-group">
                                <label>Last name</label>    
                                <Field type="text" name="lastName" />
                                <ErrorMessage name="lastName" render={() => <FormValidationError errorMessage={errors.lastName} />} />
                            </div> 
                            <div className="form-group">
                                <label>Email</label>    
                                <Field type="email" name="email" />
                                <ErrorMessage name="email" render={() => <FormValidationError errorMessage={errors.email} />} />
                            </div>    
                            <div className="form-group">
                                <label>Password</label>    
                                <Field type="password"  name="password"/>
                                <ErrorMessage name="password" render={() => <FormValidationError errorMessage={errors.password} />} />
                            </div>
                            
                            <ErrorMessage
                                name="error"
                                render={() => <FormValidationError errorMessage={errors.error} />}
                            />

                            <div className="long-text">
                                <small className="sign-up-terms">By clicking Agree & Create account, you agree to the Yodativity <Link to="/terms-and-conditions" target={"_blank"}>Terms and Conditions</Link>, <Link to="/privacy-policy" target={"_blank"}>Privacy Policy</Link>, and <Link to="/cookie-policy" target={"_blank"}>Cookie Policy</Link>.</small>
                                <br/>
                                <button type="submit" disabled={!isValid || !dirty || isSubmitting} className="primary">
                                    {isSubmitting ? "Creating account" : "Agree & Create account"}
                                </button>
                                {userStore.logInError && <FormValidationError errorMessage={userStore.logInError}>
                                    <small className="header-label-light">Please check your details are correct. Or contact us for help creating your account.</small>
                                </FormValidationError>}
                                <p className="block-text">Already have an account? <Link to="/login" className="login-alternative">Log in instead</Link></p>
                            </div>
                            
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default observer(Signup);