import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";

import * as serviceWorker from './serviceWorker';

import App from './app/layout/App';

import './style/index.css';
import './style/forms.css';
import './style/sub-navs.css';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <BrowserRouter> 
        <App />
    </BrowserRouter>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();