import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";

import { useStore } from "../../../../../app/stores/store";

import ActivityLogEntrySummaryList from "./activity-log-entry-summary/ActivityLogEntrySummaryList";
import CalendarPeriodOverviewNoData from "./CalendarPeriodOverviewNoData";
import Chart from "./charts/Chart";
import CustomActions from "./custom-actions/CustomActions";
import DatePickerSection from "./date-picker-section/DatePickerSection";
import GenericChartBasic from "../../../../../app/layout/charts/components/GenericChartBasic";
import Loading from "../../../../../app/layout/Loading";

import "./overview.css";

const CalendarPeriodOverview = () => {

    const { activityReportsStore, activityStore } = useStore();

    useEffect(
        () => {

            activityReportsStore.loadEntriesSummary(activityReportsStore.selectedStartDate, activityReportsStore.selectedActivityType);
            
            if (!(activityStore.activities && activityStore.activities.length)) activityStore.loadActivities();
            
        }, [
            activityReportsStore,
            activityReportsStore.selectedStartDate,
            activityStore,
        ]);

    if (!activityReportsStore.entriesSummary)
        return <CalendarPeriodOverviewNoData />;

    return (<>
        {activityReportsStore.loading && <Loading />}
        <div className="calendar-week-overview section-card">
            <DatePickerSection
                overviewPeriodText={activityReportsStore.entriesSummary.periodText}
                weekCommencingDate={activityReportsStore.entriesSummary.weekCommencingDate} />

            <Chart />
            
            <CustomActions />

            <GenericChartBasic startDate={activityReportsStore.entriesSummary.weekCommencingDate} />

            <ActivityLogEntrySummaryList entrySummaries={activityReportsStore.entriesSummary.entrySummaries} />
            
        </div>
    </>);
}

export default observer(CalendarPeriodOverview);