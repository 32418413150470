import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { Link, useParams } from "react-router-dom";

import { defaultEntry } from "../../../calendar-week-view/overview/helpers/entryFormHelper";
import { toOptionList } from "../../../../../../app/models/form-fields/formFieldHelper";
import { useStore } from "../../../../../../app/stores/store";

import ActivityLogEntryDashboardListItem from "./ActivityLogEntryDashboardListItem";
import ActivityLogEntryForm from "../details/ActivityLogEntryForm";
import CreateButton from "../../../../../../app/layout/common-components/CreateButton";
import Loading from "../../../../../../app/layout/Loading";
import NoResultsFound from "../../../../../../app/layout/NoResultsFound";

import "./dashboard.css";

const ActivitiesLogEntryDashboard = () => {

    const params = useParams();

    const { activityEntryStore, activityStore } = useStore();

    const [showModal, setShowModal] = useState(false);

    const handleCancel = () => setShowModal(false);

    const handleSubmit = async (entry: any) => {
        
        const apiCall = entry.id ? activityEntryStore.updateActivityEntry : activityEntryStore.createActivityEntry;

        await apiCall(entry);
        await activityStore.loadActivities();

        setShowModal(false);
    };

    const handleConfirmedDelete = async () => {

        if (!activityEntryStore.activityEntry?.id) return;

        await activityEntryStore.deleteActivityEntry(activityEntryStore.activityEntry.id);

        setShowModal(false);
    }

    const handleSelectEntry = async (id: string) => {
        
        await activityEntryStore.loadActivityEntry(id);
        setShowModal(true);
    }

    const entryForm = <ActivityLogEntryForm
                        initialValues={activityEntryStore.activityEntry || defaultEntry}
                        activities={toOptionList(activityStore?.activities)}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        handleCancel={handleCancel}
                        handleConfirmedDelete={handleConfirmedDelete}
                        handleSubmit={handleSubmit} />;

    useEffect(
        () => {

            activityEntryStore.setSelectedDate(params.date);
            activityEntryStore.setSelectedActivityType(params.activityType ? parseInt(params.activityType) : undefined);
            
            if (!(activityEntryStore.activityEntries && activityEntryStore.activityEntries.length)) {
                activityEntryStore.loadActivityEntries(activityEntryStore.selectedDate, activityEntryStore.selectedActivityType);
                activityStore.loadActivities();
            }

            if (!showModal) {
                activityEntryStore.clearSelectedActivityEntry();
            }
        }, [
            showModal,
            params.date,
            params.activityType,
            activityEntryStore,
            activityStore,
            activityEntryStore.activityEntry?.id
        ]);

    if (!(activityEntryStore.activityEntries && activityEntryStore.activityEntries.length)) return (
        <NoResultsFound message="There are no entries for this period. Click the plus button to add a new one.">
            <CreateButton handleClick={() => setShowModal(true)} />
            {entryForm}
        </NoResultsFound>);

    return (
        <>
            {(activityEntryStore.loading || activityStore.loading) && <Loading />}
            <div className="section-card">
                <Link to="/time/overview">Back</Link>
                <h1 className="mb-5">{activityEntryStore.selectedDateText}</h1>

                <CreateButton handleClick={() => setShowModal(true)} />
                {activityEntryStore.activityEntries.map(e =>
                    <ActivityLogEntryDashboardListItem
                        key={e.id}
                        entry={e}
                        onClick={() => handleSelectEntry(e.id!)} />)}
                
            </div>

            {entryForm}
        </>);
}

export default observer(ActivitiesLogEntryDashboard);