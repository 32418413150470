import IActivityLogEntry from "../../../../../../app/models/features/time-tracking/IActivityLogEntry";

import ActivityEntryStore from "../../../../../../app/stores/time-tracking/activityEntryStore";
import ActivityReportsStore from "../../../../../../app/stores/time-tracking/activityReportsStore";
import ActivityStore from "../../../../../../app/stores/settings/activityStore";


export const defaultEntry = { 
    activityId: undefined,
    newActivityName: undefined,
    newActivityType: undefined,
    startTime: undefined,
    durationHoursPart: undefined,
    durationMinutesPart: undefined,
} as IActivityLogEntry;

export const submitNewEntryForm = (
    activityEntryStore: ActivityEntryStore,
    activityReportsStore: ActivityReportsStore,
    activityStore: ActivityStore,
    closeForm: () => void,
) => async (entry: any) => {
    
    await activityEntryStore.createActivityEntry(entry);
    await activityStore.loadActivities();
    await activityReportsStore.loadEntriesSummary(activityReportsStore.selectedStartDate, activityReportsStore.selectedActivityType);
    closeForm();
};

const entryFormHelper = {
    defaultEntry,
    submitNewEntryForm,
};

export default entryFormHelper;