import React from "react";
import { observer } from "mobx-react-lite";
import { Outlet } from "react-router-dom";

import MembersSubNav from "../../../../nav/sub-nav/MembersSubNav";

import "./dashboard.css";

const CalendarWeekViewDashboard = () => {

    const links = [
        { url: "/time/overview", text: "Overview" },
    ]

    return (
        <div>
            <h1>Time tracker</h1>

            <MembersSubNav links={links} />

            <Outlet />
        </div>);
}

export default observer(CalendarWeekViewDashboard);