import React from "react";
import { observer } from "mobx-react-lite";
import { Form, Formik } from "formik";

import activityTypes from "../../../../../../app/common/enums/activityTypes";
import enumHelper from "../../../../../../app/common/enums/helpers/enumHelper";
import { useStore } from "../../../../../../app/stores/store";

import SelectField from "../../../../../../app/layout/form-fields/SelectField";

const ActivityTypeForm = () => {

    const { activityReportsStore } = useStore();

    const handleSelection = async (e: React.ChangeEvent<any>) => {
        
        if (!e.target.value)  {
            activityReportsStore.clearSelectedActivityType();
            await activityReportsStore.loadEntriesSummary(activityReportsStore.selectedStartDate, activityReportsStore.selectedActivityType);
            return;
        }

        const selectedActivityType = Object.entries(activityTypes).filter(x => x[1].value === parseInt(e.target.value));

        if (!selectedActivityType) {
            activityReportsStore.clearSelectedActivityType();
        } else {
            activityReportsStore.setSelectedActivityType(selectedActivityType[0][1]);
        }

        await activityReportsStore.loadEntriesSummary(activityReportsStore.selectedStartDate, activityReportsStore.selectedActivityType);
    };

    return (
        <Formik
            initialValues={{ activityType: "", error: null }}
            onSubmit={e => { return; }}>

            {({ handleSubmit }) => 
                (<Form onSubmit={handleSubmit} className="custom-form">
                    
                    <SelectField
                        name="activityType"
                        placeholderOption="Show all entries"
                        options={enumHelper.enumToOptionList(activityTypes)}
                        basicField={true}
                        selectionIsOptional={true}
                        onChange={handleSelection} />
                        
                </Form>)}
        </Formik>);
}

export default observer(ActivityTypeForm);