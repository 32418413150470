import { observer } from "mobx-react-lite";

import { useQuery } from "../../../app/common/helpers/navigationHelper";

import ResendConfirmationLink from "./ResendConfirmationLink";

const RegisterSuccess = () => {

    const email = useQuery().get("email") as string;

    return <div>
        <p>Registration successful. Please check your inbox for a confirmation email.</p>

        <p>Didn't receive the email? You can resend below.</p>
        
        <ResendConfirmationLink email={email} /> 
    </div>;
}

export default observer(RegisterSuccess);