import React from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

import Badge from "react-bootstrap/esm/Badge";

import IActivityLogEntrySummary from "../../../../../../app/models/features/time-tracking/IActivityLogEntrySummary";

interface IProps {
    summary: IActivityLogEntrySummary;
}

const ActivityLogEntrySummary: React.FC<IProps> = ({
    summary: {
        id ,
        activityType,
        activityTypeText,
        periodName,
        startDate,
        startDateIso,
        duration,
    }
}) => {

    return (<>
        <Link to={`/activities/${startDateIso}/${activityType}`} key={id} className="entry clickable block-link block-list-item">
            <div className="custom-title">
                <span className="period-name">{periodName}</span>
                <span className="start-date">{startDate}</span>
            </div>
            <div className="activity-type">
                <Badge pill>{activityTypeText}</Badge>
            </div>
            <div className="stats">
                <span className="duration">{duration}</span>
            </div>
        </Link>
    </>);
}

export default observer(ActivityLogEntrySummary);