import React from "react";
import { observer } from "mobx-react-lite";

import IGenericFormProps from "../../../../../app/models/form-fields/IGenericFormProps";

import activityTypes from "../../../../../app/common/enums/activityTypes";
import enumHelper from "../../../../../app/common/enums/helpers/enumHelper";

import DeleteButton from "../../../../../app/layout/form-fields/DeleteButton";
import GenericFormBasic from "../../../../../app/layout/form-fields/GenericFormBasic";
import GenericModal from "../../../../../app/layout/modals/GenericModal";
import SelectField from "../../../../../app/layout/form-fields/SelectField";
import TextInputField from "../../../../../app/layout/form-fields/TextInputField";


const ActivitySettingsForm: React.FC<IGenericFormProps<any>> = ({
    initialValues,
    showModal,
    setShowModal,
    handleCancel,
    handleConfirmedDelete,
    handleSubmit,
}) => {
    return (
        <GenericModal
            showModal={showModal}
            title="Set up an activity"
            confirmMessage="Save"
            cancelMessage="Cancel"
            handleConfirmed={handleSubmit}
            handleCancel={() => setShowModal(false)}
            noFooter={true}>
                
                <GenericFormBasic
                    initialValues={initialValues}
                    handleSubmit={handleSubmit}
                    handleCancel={handleCancel}
                    deleteButton={handleConfirmedDelete && <DeleteButton handleConfirmDelete={handleConfirmedDelete} classes="position-absolute end-0 bottom-0 pb-3" />}
                    buttonType="submit"
                >
        
                    <TextInputField
                        label="Activity"
                        name="name"
                        placeholder="Activity name"
                        required
                        errorMessage="Activity is required"
                        type="text" />  
                    
                    <SelectField
                        label="Type"
                        name="activityType"
                        selectDefault
                        options={enumHelper.enumToOptionList(activityTypes)} />
                        
                </GenericFormBasic>

        </GenericModal>);
}

export default observer(ActivitySettingsForm);