import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import IActivityLogEntry from "../../../../../../app/models/features/time-tracking/IActivityLogEntry";
import IGenericFormProps from "../../../../../../app/models/form-fields/IGenericFormProps";
import ISelectOption from "../../../../../../app/models/form-fields/ISelectOption";

import activityTypes from "../../../../../../app/common/enums/activityTypes";
import enumHelper from "../../../../../../app/common/enums/helpers/enumHelper";

import DatePickerField from "../../../../../../app/layout/form-fields/DatePickerField";
import DeleteButton from "../../../../../../app/layout/form-fields/DeleteButton";
import GenericFormBasic from "../../../../../../app/layout/form-fields/GenericFormBasic";
import GenericModal from "../../../../../../app/layout/modals/GenericModal";
import SelectField from "../../../../../../app/layout/form-fields/SelectField";
import TextInputField from "../../../../../../app/layout/form-fields/TextInputField";

interface IProps extends IGenericFormProps<IActivityLogEntry> {
    activities: ISelectOption[];
}

const ActivityLogEntryForm: React.FC<IProps> = ({
    initialValues,
    activities,
    showModal,
    setShowModal,
    handleCancel,
    handleConfirmedDelete,
    handleSubmit,
}) => {

    const [showNewActivity, setShowNewActivity] = useState(false);

    useEffect(() => {
      
        if (!showModal) {
            setShowNewActivity(false);
        }
    
    }, [showNewActivity, showModal])
    

    return (
        <GenericModal
            showModal={showModal}
            title="Record time spent"
            confirmMessage="Save"
            cancelMessage="Cancel"
            handleConfirmed={() => console.log("Saved")}
            handleCancel={() => setShowModal(false)}
            noFooter={true}>
                
                <GenericFormBasic
                    initialValues={initialValues}
                    handleSubmit={handleSubmit}
                    handleCancel={handleCancel}
                    deleteButton={handleConfirmedDelete && <DeleteButton handleConfirmDelete={handleConfirmedDelete} classes="position-absolute end-0 bottom-0 pb-3" />}
                    buttonType="submit">
                    
                        <>
                            {!showNewActivity && 
                                <div>
                                    <SelectField
                                        label="Activity"
                                        name="activityId"
                                        options={activities}
                                        selectDefault={!(initialValues.id!!)}
                                        errorMessage="Activity is required" />

                                    <div
                                        className="link-button"
                                        onClick={() => setShowNewActivity(!showNewActivity)}
                                    >
                                        New activity
                                    </div>
                                </div>}
                        </>

                        <>
                            {showNewActivity && 
                                <div>
                                    <TextInputField
                                        label="Activity"
                                        name="newActivityName"
                                        placeholder="Activity name"
                                        type="text" />

                                    <div
                                        className="link-button"
                                        onClick={() => setShowNewActivity(!showNewActivity)}
                                    >
                                        Use existing activity instead
                                    </div>    
                                    
                                    <SelectField
                                        label="Type"
                                        name="newActivityType"
                                        selectDefault
                                        options={enumHelper.enumToOptionList(activityTypes)} />

                                    <hr/>
                                </div>}
                        </>

                        <DatePickerField
                            label="Start time"
                            name="startTime"
                            showTimeInput
                            timeInputLabel="Time"
                            locale="en-GB"
                            dateFormat="Pp"
                            errorMessage="Start time is required" />

                        
                        <div className="side-by-side-fields">
                            <TextInputField
                                label="Hour(s)"
                                name="durationHoursPart"
                                type="number"
                                errorMessage="Duration is required" />

                            <TextInputField
                                label="Minute(s)"
                                name="durationMinutesPart"
                                type="number"
                                errorMessage="Duration is required" />
                        </div>

                        

                </GenericFormBasic>

        </GenericModal>);
}

export default observer(ActivityLogEntryForm);